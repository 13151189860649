import { useDispatch, useSelector } from "react-redux";
import {
  clearDetailedPageState,
  clearNewsletters,
  getNewsletters,
  getSponsoredNewsletters,
  incrementPageNumber,
  resetPageNumber,
  selectAllNewsletters,
  selectIsLoadingNewsletters,
  selectSponsoredNewsletters,
  selectSponsoredNewslettersIsLoading,
} from "../store/newslettersSlice";
import { Fragment, useCallback, useEffect, useState } from "react";
import NewsletterCard from "../components/NewsletterCard";
import { DateTime } from "luxon";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";
import { selectIndustries } from "../store/globalSlice";
import { IndustryBadge } from "../components/IndustryBadge";
import LoadingSpinner from "../components/LoadingSpinner";
import SponsorNewsletterCard from "../components/SponsorNewsletterCard";
import { LegalLinks } from "../components/LegalLinks";
import { useParams } from "react-router-dom";
import facebook from "../components/images/facebook.png";
import twitter from "../components/images/twitter.png";
import client from "../client";

const ONE_DAY = 86400000;

const SORT_OPTIONS = [
  {
    label: "Trending Today",
    titleDisplay: "Today's Top Trending",
    sortMode: "UPVOTES_TODAY",
    startDate: DateTime.now().minus(ONE_DAY),
  },
  {
    label: "Trending This Week",
    titleDisplay: "This Week's Top Trending",
    sortMode: "UPVOTES_WEEK",
    startDate: DateTime.now().minus(ONE_DAY * 7),
  },
  { label: "Top Rated", titleDisplay: "Highest Rated", sortMode: "RATING" },
  {
    label: "Recently Added",
    titleDisplay: "Recently Added",
    sortMode: "CREATION",
  },
];

export default function NewsletterHomePage() {
  const dispatch = useDispatch();
  const newsletters = useSelector(selectAllNewsletters);
  const isLoadingNewsletters = useSelector(selectIsLoadingNewsletters);
  const sponsoredNewsletters = useSelector(selectSponsoredNewsletters);
  const sponsoredNewslettersIsLoading = useSelector(
    selectSponsoredNewslettersIsLoading
  );
  const industries = useSelector(selectIndustries);
  const { category } = useParams();
  const [sortMode, setSortMode] = useState(SORT_OPTIONS[0]);
  const [filterIndustry, setFilterIndustry] = useState(null);

  // Set the filter industry based on URL parameter
  useEffect(() => {
    if (category && industries) {
      const matchingIndustry = industries.find(
        ind => ind.name.toLowerCase() === category.replace("-", " ").toLowerCase()
      );
      if (matchingIndustry) {
        setFilterIndustry(matchingIndustry);
      }
    }
  }, [category, industries]);

  const titlePieces = [sortMode.titleDisplay];
  if (filterIndustry) titlePieces.push(filterIndustry.name);
  titlePieces.push("Newsletters");
  const title = titlePieces.join(" ");

  useEffect(() => {
    dispatch(clearDetailedPageState());
    dispatch(getSponsoredNewsletters());
  }, []);

  const getNewslettersFunction = useCallback(() => {
    const params = {
      sortMode: sortMode.sortMode,
    };
    if (filterIndustry) params.industryId = filterIndustry.id;
    if (sortMode.startDate) {
      params.startDate = sortMode.startDate.toISO();
    }
    dispatch(getNewsletters(params));
  }, [dispatch, sortMode, filterIndustry]);

  // Reset the page and clear the results whenever a filter is selected
  useEffect(() => {
    dispatch(resetPageNumber());
    dispatch(clearNewsletters());
    getNewslettersFunction();
  }, [dispatch, sortMode, filterIndustry]);

  useEffect(() => {
    const onScroll = function () {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        dispatch(incrementPageNumber());
        getNewslettersFunction();
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [dispatch]);

  return (
    <div className="grid grid-cols-3 px-6 lg:px-12 xl:px-48 pt-16 gap-6 sm:gap-10">
      {!filterIndustry && (
        <div className="col-span-3">
          <div className="flex items-center">
            <div className="font-bold text-lg mr-4">Categories</div>
            <div className="hidden sm:flex flex-row space-x-2">
              {industries &&
                industries.slice(0, 5).map((industry) => (
                  <div
                    key={industry.id}
                    onClick={() => setFilterIndustry(industry)}
                    className="cursor-pointer"
                  >
                    <IndustryBadge industry={industry} />
                  </div>
                ))}
            </div>

            <Popover className="relative ml-10">
              <Popover.Button className="inline-flex items-center gap-x-1 text-md font-semibold leading-6 text-gray-900">
                <span>More Categories</span>
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
                  <div className="hidden md:block w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                    {industries &&
                      industries.slice(5).map((industry) => (
                        <button
                          key={industry.name}
                          className="block p-2 hover:text-indigo-600"
                          onClick={() => setFilterIndustry(industry)}
                        >
                          <IndustryBadge industry={industry} />
                        </button>
                      ))}
                  </div>
                  <div className="block md:hidden w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                    {industries &&
                      industries.map((industry) => (
                        <button
                          key={industry.name}
                          className="block p-2 hover:text-indigo-600"
                          onClick={() => setFilterIndustry(industry)}
                        >
                          <IndustryBadge industry={industry} />
                        </button>
                      ))}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        </div>
      )}
      {filterIndustry && (
        <div className="col-span-3">
          <button onClick={() => { setFilterIndustry(null); window.history.pushState({}, '', '/'); }} className="font-bold">
            <ChevronLeftIcon className="w-6 h-6 inline" />
            Go back to all newsletters
          </button>
        </div>
      )}

      <div className="col-span-3 w-full h-[1px] bg-gray-200"></div>
      <div className="col-span-3 lg:col-span-2">
        <div className="flex flex-col sm:flex-row">
          <div className="text-xl font-bold my-2 flex-1">{title}</div>
          <div className="flex-shrink-0">
            <Popover className="relative">
              <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                <span>{sortMode.label}</span>
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
                  <div className="w-48 rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                    {SORT_OPTIONS.map((sortOption) => (
                      <Popover.Button
                        key={sortOption.label}
                        className="block p-2 hover:text-indigo-600"
                        onClick={() => {
                          setSortMode(sortOption);
                        }}
                      >
                        {sortOption.label}
                      </Popover.Button>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        </div>
        {newsletters.map((newsletter) => (
          <div key={newsletter.id}>
            <NewsletterCard newsletterId={newsletter.id} />
          </div>
        ))}
        {(!newsletters || newsletters.length === 0) && (
          <div className="w-full text-center mt-16">
            There are no newsletters for this category. Add some using the "Add
            a Newsletter" button!
          </div>
        )}
        <div className="mt-10 mx-auto text-center w-full h-32">
          {isLoadingNewsletters && <LoadingSpinner />}
        </div>
      </div>
      <div className="hidden lg:block md:col-span-1">
        <div className="text-lg font-bold ml-8">Sponsored Newsletters</div>
        {sponsoredNewslettersIsLoading && (
          <div className="w-full text-center mt-4">
            <LoadingSpinner />
          </div>
        )}
        {sponsoredNewsletters &&
          sponsoredNewsletters.map((newsletter) => (
            <div key={newsletter.id}>
              <SponsorNewsletterCard newsletter={newsletter} />
            </div>
          ))}
        <div className="mt-4 italic text-sm text-gray-600 ml-8">
          Want to sponsor your newsletter? Contact us{" "}
          <a
            href="mailto:newsletter.surf@gmail.com"
            className="text-indigo-600"
          >
            here
          </a>
          !
        </div>
        <div className="mt-4 p-4 bg-indigo-50 rounded-lg ml-8">
          <h3 className="font-semibold text-indigo-900">Newsletter Creator?</h3>
          <p className="mt-2 text-sm text-indigo-800">
            Get AI-powered insights and reader feedback analysis with our new platform.{" "}
            <a href="/platform" className="font-medium text-indigo-600 hover:text-indigo-500">
              Learn more →
            </a>
          </p>
        </div>
        <div className="mt-6 ml-8">
          <a
            href="https://www.producthunt.com/posts/newsletter-surf?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-newsletter&#0045;surf"
            target="_blank"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=417279&theme=light"
              alt="newsletter&#0046;surf - Discover&#0032;your&#0032;next&#0032;favorite&#0032;newsletter | Product Hunt"
              style={{ width: "250px", height: "54px" }}
              width="250"
              height="54"
            />
          </a>
        </div>
      </div>
      <div className="col-span-3 mt-4 ml-8">
        <LegalLinks />
      </div>
    </div>
  );
}
