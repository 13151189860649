import React, { useState } from 'react';
import client from '../client';
import PlatformImage from './images/platform_example.png';
import { LegalLinks } from '../components/LegalLinks';

export default function PlatformPage() {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    
    try {
      await client.joinWaitlist(email);
      setIsSubmitted(true);
      setEmail('');
    } catch (err) {
      setError('Something went wrong. Please try again.');
      console.error('Waitlist error:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-white">
      {/* Hero section */}
      <div className="relative isolate overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
            <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Make better newsletters through reader feedback
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Understand your readers better, get AI-powered insights, and improve your newsletter's engagement with data-driven suggestions.
            </p>
            <div className="mt-10">
              <form onSubmit={handleSubmit} className="flex max-w-md gap-x-4">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isSubmitting}
                />
                <button
                  type="submit"
                  className="flex-none rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={isSubmitting || email.trim() === ''}
                >
                  {isSubmitting ? 'Joining...' : 'Join waitlist'}
                </button>
              </form>
              {isSubmitted && (
                <p className="mt-3 text-sm text-green-600">
                  ✓ You're on the waitlist! We'll notify you when we launch.
                </p>
              )}
              {error && (
                <p className="mt-3 text-sm text-red-600">
                  {error}
                </p>
              )}
            </div>
          </div>
          <div className="mx-auto mt-16 flex max-w-xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
            <div className="w-full lg:max-w-xl xl:max-w-xl">
              <div className="rounded-md bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-xl lg:p-4">
                <div className="relative w-full">
                  <img
                    src={PlatformImage}
                    alt="Example of the newsletter platform showcasing reader feedback and insights"
                    className="w-full h-auto object-contain rounded-md shadow-2xl ring-1 ring-gray-900/10"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Feature section */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-24 sm:py-16">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Grow faster</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Everything you need to understand your newsletter's impact
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            <div className="flex flex-col">
              <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                AI-Summarized Suggestions
              </dt>
              <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                <p className="flex-auto">
                  Get intelligent insights from your reader feedback, automatically summarized and categorized to help you make data-driven improvements.
                </p>
              </dd>
            </div>
            <div className="flex flex-col">
              <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                Content-Driven Review Trends
              </dt>
              <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                <p className="flex-auto">
                  Understand how your content resonates with readers through detailed analytics that connect feedback to specific newsletter content.
                </p>
              </dd>
            </div>
            <div className="flex flex-col">
              <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                ... and more!
              </dt>
              <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                <p className="flex-auto">
                  A/B test content to see what works best with your audience, analyze reader profiles, and more. Have an idea for a feature? <a href="mailto:newsletter.surf@gmail.com" className="text-indigo-600 hover:text-indigo-500">Let us know!</a>
                </p>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      {/* Footer */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-12">
        <div className="mt-8">
          <LegalLinks />
        </div>
      </div>
    </div>
  );
} 